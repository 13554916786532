/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AccountUsersService } from './services/AccountUsersService';
import { AttachmentsService } from './services/AttachmentsService';
import { DocumentsService } from './services/DocumentsService';
import { GeneralPurposesService } from './services/GeneralPurposesService';
import { MessagesService } from './services/MessagesService';
import { SupportTicketsService } from './services/SupportTicketsService';
import { SynergyMockService } from './services/SynergyMockService';
import { UserManagementService } from './services/UserManagementService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class CpApi {
    public readonly accountUsers: AccountUsersService;
    public readonly attachments: AttachmentsService;
    public readonly documents: DocumentsService;
    public readonly generalPurposes: GeneralPurposesService;
    public readonly messages: MessagesService;
    public readonly supportTickets: SupportTicketsService;
    public readonly synergyMock: SynergyMockService;
    public readonly userManagement: UserManagementService;
    public readonly users: UsersService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.accountUsers = new AccountUsersService(this.request);
        this.attachments = new AttachmentsService(this.request);
        this.documents = new DocumentsService(this.request);
        this.generalPurposes = new GeneralPurposesService(this.request);
        this.messages = new MessagesService(this.request);
        this.supportTickets = new SupportTicketsService(this.request);
        this.synergyMock = new SynergyMockService(this.request);
        this.userManagement = new UserManagementService(this.request);
        this.users = new UsersService(this.request);
    }
}

