import axios from "axios";
import * as Sentry from "@sentry/vue";
import type { AxiosRequestConfig } from 'axios';

axios.defaults.baseURL = import.meta.env.VUE_APP_URL + "/api/";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers["Content-Type"] = "application/json";

const sessionStorageLoginInformationKey = `oidc.user:https://${import.meta.env.VUE_APP_ZITADEL_DOMAIN}:${import.meta.env.VUE_APP_ZITADEL_CLIENTID}`;

axios.interceptors.response.use(
  (response) => {
      return response;
  },
  async (error) => {
      if (error.response && error.response.status === 401) {
        sessionStorage.removeItem(sessionStorageLoginInformationKey);
      }
      return Promise.reject(error);
  }
);

const ApiService = {
  getBaseUrl() {
    return import.meta.env.VUE_APP_URL + "/api/"; 
  },
  setHeader() {
    const loginInformation = JSON.parse(sessionStorage.getItem(sessionStorageLoginInformationKey) || "{}");

    if (loginInformation.id_token) {
      axios.defaults.headers.Authorization = `Bearer ${loginInformation.id_token}`;
    } else {
      axios.defaults.headers.common.Authorization = "";
    }

    Sentry.continueTrace
  },

  get(resource: any, config?: AxiosRequestConfig) {
    this.setHeader();
    return axios.get(resource, config);
  },
  post(resource: any, data: any) {
    this.setHeader();
    return axios.post(resource, data);
  },

  put(resource: any, data: any) {
    this.setHeader();
    return axios.put(resource, data);
  },

  delete(resource: any) {
    this.setHeader();
    return axios.delete(resource);
  },

  customRequest(data: any) {
    this.setHeader();
    return axios(data);
  },

  download(resource: any) {
    this.setHeader();
    return axios.get(resource, {responseType: 'blob'});
  },
};

export default ApiService;
