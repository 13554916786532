/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PortalSupportTicketDto } from '../models/PortalSupportTicketDto';
import type { SupportTicketDto } from '../models/SupportTicketDto';
import type { SupportTicketDtoIPageModel } from '../models/SupportTicketDtoIPageModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SupportTicketsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns SupportTicketDtoIPageModel Success
     * @throws ApiError
     */
    public getApiSupportTickets({
        pageNo,
        pageSize,
        searchParameter,
        orderBy,
        filters,
        filtersNotEqual,
        lessThan,
        greaterThan,
    }: {
        pageNo?: number,
        pageSize?: number,
        searchParameter?: string,
        orderBy?: Record<string, string>,
        filters?: Record<string, Array<string>>,
        filtersNotEqual?: Record<string, Array<string>>,
        lessThan?: Record<string, Array<string>>,
        greaterThan?: Record<string, Array<string>>,
    }): CancelablePromise<SupportTicketDtoIPageModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/support-tickets',
            query: {
                'PageNo': pageNo,
                'PageSize': pageSize,
                'SearchParameter': searchParameter,
                'OrderBy': orderBy,
                'Filters': filters,
                'FiltersNotEqual': filtersNotEqual,
                'LessThan': lessThan,
                'GreaterThan': greaterThan,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns SupportTicketDto Success
     * @throws ApiError
     */
    public postApiSupportTickets({
        requestBody,
    }: {
        requestBody?: PortalSupportTicketDto,
    }): CancelablePromise<SupportTicketDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/support-tickets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns SupportTicketDto Success
     * @throws ApiError
     */
    public getApiSupportTickets1({
        id,
    }: {
        id: number,
    }): CancelablePromise<SupportTicketDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/support-tickets/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns SupportTicketDto Success
     * @throws ApiError
     */
    public putApiSupportTickets({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: PortalSupportTicketDto,
    }): CancelablePromise<SupportTicketDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/support-tickets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns SupportTicketDto Success
     * @throws ApiError
     */
    public getApiSupportTicketsExact({
        exactId,
    }: {
        exactId: string,
    }): CancelablePromise<SupportTicketDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/support-tickets/exact/{exactId}',
            path: {
                'exactId': exactId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public postApiSupportTicketsSubscribe({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/support-tickets/{id}/subscribe',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public postApiSupportTicketsUnsubscribe({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/support-tickets/{id}/unsubscribe',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
}
