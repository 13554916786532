import type { VueOidcSettings } from "vue3-oidc";
import { createOidc, useOidcStore } from "vue3-oidc";
import { unref } from "vue";

const { state } = useOidcStore();

const oidcSettings: VueOidcSettings = {
  authority: `https://${import.meta.env.VUE_APP_ZITADEL_DOMAIN}`,
  scope: "openid email profile urn:zitadel:iam:org:project:id:zitadel:aud urn:zitadel:iam:org:project:roles",
  client_id: import.meta.env.VUE_APP_ZITADEL_CLIENTID,
  redirect_uri: import.meta.env.VUE_APP_URL + '/oidc-callback',
  post_logout_redirect_uri: import.meta.env.VUE_APP_URL + '/signout',
  silent_redirect_uri: import.meta.env.VUE_APP_URL + '/oidc-callback-silent',
  popup_redirect_uri: import.meta.env.VUE_APP_URL + '/oidc-callback-popup', 
  response_type: "code",
  loadUserInfo: true,
  response_mode: "query",
  onSigninRedirectCallback(user) {
    console.trace()
    location.href = unref(state).redirect_uri || "/";
  },
};

createOidc({
  oidcSettings: oidcSettings, //your oidc settings
  auth: false, //if auth is true,will auto authenticate
  events: {}, //your oidc customization callback events
  refreshToken: {
    enable: false,
    time: 30000,
  },
}); 