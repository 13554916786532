/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleDto } from '../models/RoleDto';
import type { UserGrantDto } from '../models/UserGrantDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserManagementService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns RoleDto Success
     * @throws ApiError
     */
    public getApiUserManagementGrants(): CancelablePromise<RoleDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user-management/grants',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @returns UserGrantDto Success
     * @throws ApiError
     */
    public getApiUserManagementUserGrants({
        id,
    }: {
        id: string,
    }): CancelablePromise<UserGrantDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/user-management/user/{id}/grants',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public postApiUserManagementUserGrants({
        id,
        requestBody,
    }: {
        id: string,
        requestBody?: Array<string>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/user-management/user/{id}/grants',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public putApiUserManagementUserGrants({
        id,
        grantId,
        requestBody,
    }: {
        id: string,
        grantId: string,
        requestBody?: Array<string>,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/user-management/user/{id}/grants/{grantId}',
            path: {
                'id': id,
                'grantId': grantId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
    /**
     * @returns any Success
     * @throws ApiError
     */
    public deleteApiUserManagementUserGrantsRemove({
        id,
        grantId,
    }: {
        id: string,
        grantId: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/user-management/user/{id}/grants/{grantId}/remove',
            path: {
                'id': id,
                'grantId': grantId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
}
