<template>
  <n-config-provider :locale="nlNL" :date-locale="dateNlNL">
  <div v-if="user.authenticationError">
    {{ user.authenticationError }}
  </div>

  <template v-if="user.isAuthenticated && user.srConnected">
    <header v-if="!hideNavigation" class="navbar navbar-dark sticky-top bg-purple-5 flex-md-nowrap p-0 ">
      <a class="logo navbar-brand col-md-3 col-lg-2 me-0 px-3 fs-6" href="#">
        <img src="@/assets/images/bis-econocom.svg" />
      </a>
      <button class="navbar-toggler position-absolute d-md-none collapsed"
        type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
        aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
      <div class="navbar-nav">
        <button class="dropbtn" @click="toggleUserMenu">
          <img :src="user.profileImageUrl" /> 
          {{ user.name }}
          <span>({{ user.email }})</span>
          <img v-show="showUserMenu == true" src="@/assets/images/arrow-down.svg" style="transform:rotate(180deg);" />
          <img v-show="showUserMenu == false" src="@/assets/images/arrow-down.svg" />
        </button>

        <div v-if="showUserMenu" class="dropdown-content">
          <div class="nav-profile">
            <img :src="user.profileImageUrl" />
            <div>
              {{ user.name }}
              <span>{{ user.username }}</span>
            </div>
          </div>

          <a v-if="false" href="#">Mijn profiel</a>
          <a v-if="false" href="#">Instellingen</a>
          <a v-if="false" href="#">Mijn support tickets</a>
          <a href="#" @click.prevent="logout">Uitloggen</a>
        </div>
      </div>
    </header>

    <div class="container-fluid">
      <div class="row">
        <SideMenu v-if="!showSideMenu" />
        <SecondLevelMenuDesktop v-if="!hideNavigation" />

        <main 
          :class="{ 
            'hide-navigation': hideNavigation,
            'col-md-9': !showSideMenu,
            'col-lg-10': !showSideMenu,
            'col-md-12': showSideMenu,
            'col-lg-12': showSideMenu,
            'ms-sm-auto': true,
            'px-md-4': true,
          }"
        >
          
          <nav v-if="!hideNavigation" class="breadcrumb-wrapper" aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li 
                v-for="crumb in menuTree.crumbs" 
                :class="{ 'breadcrumb-item': true, 'active': crumb.active }"
              >
                <router-link :to="{ name: crumb.routeName }">{{ crumb.caption }}</router-link>
              </li>
            </ol>
          </nav>

          <div class="container-fluid p-0">
            <router-view></router-view>

            <div v-if="build != 'production' && deployDateTime" style="position: absolute; bottom: 0; right: 0; font-size: 0.75rem; padding: 2px;">{{ deployDateTime }}</div>
          </div>
        </main>
      </div>
    </div>
  </template>
  </n-config-provider>
</template>

<script lang="ts">

import { defineComponent, defineAsyncComponent, inject } from 'vue'
import { NConfigProvider } from 'naive-ui'
import { nlNL, dateNlNL } from 'naive-ui'

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    menuTree: MenuItem
  }
}

export default defineComponent({
  data() {
    return {
      showUserMenu: false,
      user: this.$user.data,
      build: import.meta.env.MODE, 
      deployDateTime: import.meta.env.VUE_APP_DEPLOY_DATETIME,
      nlNL,
      dateNlNL,
    }
  },
  components: {
    SideMenu: defineAsyncComponent(() => import('@/components/SideMenu.vue')),
    SecondLevelMenuDesktop: defineAsyncComponent(() => import('@/components/SecondLevelMenuDesktop.vue')),
    NConfigProvider,
  },
  methods: {
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu
    },

    logout() {
      this.$user.logout()
    },
  },
  computed: {
    hideNavigation () {
      return this.$route.meta.hideNavigation
    },
    showSideMenu () {
      return this.hideNavigation
    },
    menuTree () {
      // Assumes first route is the root.
      // Assumes routes ordered so that parent items are higher than children.

      const routes = this.$router.options.routes

      let nodes: { [key: string]: MenuItem } = {}
      let root = null
      let crumbs = []

      for (const [index, route] of routes.entries()) {
        if (!route.name) throw 'All routes need a name'

        const menuData = route.meta?.menu
        if (!menuData || menuData.show === false) continue

        if (
          route.name == 'users' &&
          this.user.roles != null && 
          !this.user.roles?.includes('account-admin')
        ) continue

        if (
          route.name == 'documents' &&
          this.user.roles != null && 
          !this.user.roles?.includes('view:documents')
        ) continue

        if (
          route.name == 'shop' &&
          !this.user.roles?.includes('view:webshop')
        ) continue

        if (
          route.name == 'support' &&
          !this.user.roles?.includes('view:support-tickets')
        ) continue

        let icon = menuData.icon
        if (icon) {
          icon = new URL(icon, import.meta.url).href
        }

        let node: MenuItem = {
          parent: null,
          routeName: String(route.name),
          icon: icon,
          caption: menuData.caption,
          active: route.name == this.$route.name,
          childActive: false,
          children: []
        }
        nodes[node.routeName] = node

        if (!root) {
          root = node
        } else {
          if (!menuData.parent) throw 'Missing parent'
 
          let parent = nodes[menuData.parent]
          if (!parent) continue //throw 'Parent route not found, route name: ' + menuData.parent
          node.parent = parent
          parent.children.push(node)

          if (node.active) {
            crumbs.push(node)

            while (parent.parent) {
              crumbs.push(parent)

              parent.childActive = true
              parent = parent.parent
            }

            crumbs.push(parent)
          }
        }
      }

      if (root == null) throw 'No routes'

      crumbs.reverse()
      root.crumbs = crumbs

      return root
    },
  },
  async mounted() {
  }
})

</script>

<style lang="scss">
@import "@/assets/styles/base.scss";

.navbar {
  min-height: $navbarHeight;

  .logo img {
    height: 28px;
    margin-left: 25px;
  }
}

.breadcrumb-wrapper {
  $height: calc($breadcrumb-height - (var(--bs-gutter-x) * 2));
  height: $height;
  line-height: $height;
  font-size: calc($height / 2.2);

  ol {
    padding-left: calc($height / 2);
    padding-right: calc($height / 2); 
    background-color: $breadcrumb-bg-color;
    border-radius: $height;
    margin-top: var(--bs-gutter-x);
    margin-bottom: var(--bs-gutter-x);

    li {
      color: white;
    }

    a {
      color: white;
      text-decoration: none;
    }
    
    a:hover {
      text-decoration: underline;
    }
  }
}

.dropbtn {
  background-color: $purple-5;
  color: white;
  padding: 5px 13px;
  font-size: 16px;
  border: none;
  margin-right: 10px;
  font-size: 13px;
  letter-spacing: 0.5px;
  border-radius: 4px;

  img {
    margin-left: 5px;
  }

  img:first-child {
    border: 2px #fff solid;
    border-radius: 20px;
    margin: 0 10px 0 0;
    width: 29px;
  }
}

.dropbtn:hover {
  background-color: #8c46e8;
}

.dropbtn .active {
  background-color: #8c46e8;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 246px;
  z-index: 1;
  right: 12px;
  top: 60px;
  box-shadow: 0px 2px 45px rgb(26 15 46 / 21%);
  border-radius: 4px;
  padding: 16px 16px;

  .nav-profile {
    font-weight: 700;
    font-size: 16px;
    border-bottom: 1px solid #EBE8F2;

    span {
      font-weight: 400;
      font-size: 13px;
      color: #727272;
    }

    img {
      border: 2.5px #fff solid;
      border-radius: 50px;
      margin: 0 15px 10px 10px;
      width: 50px;
      filter: drop-shadow(0px 2.97143px 5.94286px rgba(0, 0, 0, 0.09));
      float: left;
    }

    div {
      margin-bottom: 18px;

    }
  }
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:last-child {
  border-top: 1px solid #EBE8F2;
}

.dropdown-content a:hover {
  background-color: #f0edf6;
}

// .dropdown:hover .dropdown-content {
//   display: block;
// }

.dropdown:hover {
  background-color: #3e8e41;
}
</style>
