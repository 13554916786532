
import { createRouter as createVueRouter, createWebHistory } from "vue-router"
import type { RouteRecordRaw, Router }  from "vue-router"
import type { RouteLocation } from 'vue-router'
import type { App } from 'vue'
import dashboardIcon from '@/assets/images/dashboard.svg' 
import serviceIcon from '@/assets/images/service.svg' 

interface RouterMetaMenu {
  show?: boolean,
  parent?: string,
  icon?: string,
  caption?: string
}

declare module 'vue-router' {
  interface RouteMeta {
    menu?: RouterMetaMenu
  }
}

function prefixRoutes(prefix: string, menuParent: string, routes: Array<RouteRecordRaw>): Array<RouteRecordRaw> {
	return routes.map(function (route): RouteRecordRaw { 
    route.path = prefix + '/' + route.path
    if (!route.meta) route.meta = {}
    if (!route.meta.menu) route.meta.menu = {}
    if (!route.meta.menu.parent) route.meta.menu.parent = menuParent

    return route
  })
}

export function createRouter(app: App): Router {

  const routes: Array<RouteRecordRaw> = [
    {
      path: '/debug',
      name: 'debug',
      component: () => import('@/views/DebugView.vue'),
    },
    {
      path: '/',
      name: 'home',
      redirect: { name: 'support-tickets' },
      meta: {
        menu: {
          caption: 'Home'
        }
      }
    },
    {
      path: '/oidc-callback',
      name: 'oidc-callback',
      component: {}, // Will be caught in authguard
    },
    {
      path: '/oidc-callback-silent',
      name: 'oidc-callback-silent',
      component: {}, // Will be caught in authguard
    },
    {
      path: '/oidc-callback-popup',
      name: 'oidc-callback-popup',
      component: {}, // Will be caught in authguard
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        menu: {
          show: false,
          parent: 'home',
          caption: 'Dashboard',
          icon: dashboardIcon,
        }
      }
    },
    {
      name: 'internal-customer-support-ticket',
      path: '/internal/customer-support',
      component: () => import('@/views/SupportTicketDetail.vue'),
      props: route => ({
        exactId: route.query.ID
      }),
      meta: {
        hideNavigation: true
      }
    },
    {
      path: '/documents',
      name: 'documents',
      component: () => import('@/views/DocumentsView.vue'),
      meta: {
        menu: {
          parent: 'home',
          caption: 'Documents',
          //icon: serviceIcon,
        }
      }
    },
    {
      path: '/support',
      name: 'support',
      redirect: { name: 'support-tickets' },
      meta: {
        menu: {
          parent: 'home',
          caption: 'Support',
          icon: serviceIcon,
        }
      }
    },
    ...prefixRoutes('/support', 'support', 
      [
        {
          path: 'tickets',
          name: 'support-tickets',
          component: () => import('@/views/SupportTickets.vue'),
          meta: {
            menu: {
              caption: 'Tickets',
            }
          }
        },
        ...prefixRoutes('tickets', 'support-tickets', [
          {
            path: ':id',
            name: 'support-ticket-detail',
            component: () => import('@/views/SupportTicketDetail.vue'),
            props: true,
            meta: {
              menu: {
                caption: 'Ticket'
              }
            }
          },
          {
            path: 'create',
            name: 'support-ticket-create',
            component: () => import('@/views/SupportTicketCreate.vue'),
            props: true,
            meta: {
              menu: {
                caption: 'Nieuw ticket'
              }
            }
          },
        ]),
        {
          path: 'knowledge-base',
          name: 'knowledge-base',
          component: () => import('@/views/SupportKnowledgeBaseView.vue'),
          meta: {
            menu: {
              show: false,
              caption: 'Knowledge Base',
            }
          }
        },
      ]
    ),
    {
      path: '/shop',
      name: 'shop',
      component: { },
      beforeEnter() {
        window.open('https://my-shop.bis.nl/mooauth/actions/sendAuthorizationRequest', '_blank')?.focus();
      },
      meta: {
        menu: {
          parent: 'home',
          caption: 'Webshop',
        }
      }
    },
    {
      path: '/users',
      name: 'users',
      redirect: { name: 'users-list' },
      meta: {
        menu: {
          parent: 'home',
          caption: 'Gebruikers',
        }
      }
    },
    ...prefixRoutes('/users', 'users', 
    [
      {
        path: 'list',
        name: 'users-list',
        component: () => import('@/views/UsersView.vue'),
        meta: {
          roles: ['account-admin'],
          menu: {
            caption: 'Overzicht',
          }
        }
      },
      ...prefixRoutes('users', 'users', [
        {
          path: ':id',
          name: 'users-detail',
          component: () => import('@/views/UserDetailView.vue'),
          props: true,
          meta: {
            menu: {
              show: false,
              caption: 'Gebruiker'
            }
          }
        },
        {
          path: 'create',
          name: 'users-create',
          component: () => import('@/views/UserCreateView.vue'),
          props: true,
          meta: {
            menu: {
              show: false,
              caption: 'Gebruiker aanmaken'
            }
          }
        },
      ]),
      {
        path: 'knowledge-base',
        name: 'knowledge-base',
        component: () => import('@/views/SupportKnowledgeBaseView.vue'),
        meta: {
          menu: {
            show: false,
            caption: 'Knowledge Base',
          }
        }
      },
    ]
  ),
  ]

  const router = createVueRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
  })


  router.beforeEach((to: RouteLocation) => {
    return app.config.globalProperties.$user.authGuard(to)
  })

  return router
}
