import type { App } from 'vue'
import type { RouteLocation } from 'vue-router'
import { unref, reactive } from 'vue'
import md5 from 'md5'
import { CpApi } from '@/api'
import type {SupportTicketMessageDto, PortalUserDto } from '@/api'
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { useSupportTicketsStore } from '@/stores/support-ticket'
import * as Sentry from "@sentry/vue";

import { apiKey, userKey } from '@/keys'

import { useToast } from 'vue-toastification'

import { ErrorResponse, User, useAuth, useOidcStore } from 'vue3-oidc'
import  ApiService from '../services/apiService'

const { signinRedirect, signoutRedirect, autoAuthenticate } = useAuth()
const { state: authState, actions } = useOidcStore()

export interface UserProvider {
  data: UserData
  cpApi?: CpApi
  signalR?: HubConnection

  install(app: App): void
  authGuard(to: RouteLocation): Promise<string | boolean>
  getJwt(): Promise<string>,

  logout(): void
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    [PLUGIN_TOKEN]: UserProvider,
    apiKey: CpApi
  }
}

const PLUGIN_TOKEN = '$user'

const toast = useToast()

export class UserPlugin implements UserProvider {
  app?: App
  cpApi?: CpApi
  signalR?: HubConnection
  joinedTicketRooms: Record<number, {
    joined: boolean,
    startDateTime: string,
  }> = {}

  data: UserData = reactive({ isAuthenticated: false, srConnected: false })

  proxyHandler = {
    get(target: any, prop: any, receiver: any) {
      if (prop in target.data) {
        return target.data[prop]
      }

      return Reflect.get(target, prop, receiver);
    },

    set(target: any, prop: any, value: any) {
      if (prop in target) {
        return Reflect.set(target, prop, value);
      }

      target.data[prop] = value
      return true
    }
  }

  async install(app: App): Promise<void> {
    // Create Api Client
    this.cpApi = new CpApi({
      BASE: import.meta.env.VUE_APP_CP_API_BASE,
      TOKEN: this.getJwt.bind(this)
    })

    // Make available globally
    const userProxy = new Proxy(this, this.proxyHandler)
    app.config.globalProperties[PLUGIN_TOKEN] = userProxy
    app.provide(apiKey, this.cpApi)
    app.provide(userKey, userProxy)

    this.app = app

    // Set user on stores
    const supportTicketStore = useSupportTicketsStore()
    supportTicketStore.setUser(this)

    // SignalR
    this.signalR = new HubConnectionBuilder()
      .withUrl(import.meta.env.VUE_APP_CP_API_BASE + '/chatHub', {
        withCredentials: false,
        accessTokenFactory: () => { return this.getJwt() || '' },
      })
      .configureLogging(LogLevel.Information)
      .build()

    this.signalR.onclose(() => {
      this.data.srConnected = false
      console.log('SignalR connection dropped, retrying in 5 seconds')
      setTimeout(this.startSignalR, 5000)
    })

    this.signalR.onreconnected(() => {
      this.data.srConnected = true
      console.log('Reconnecting SignalR')
    })

    this.signalR.on("messageReceived", (ticketId: number, message: SupportTicketMessageDto) => {
      supportTicketStore.onMessageReceived(ticketId, message)
    })

    this.signalR.on("messageRead", (ticketId: number, user: PortalUserDto, messageId: number) => {
      supportTicketStore.onMessageRead(ticketId, user, messageId)
    })
  }

  async startSignalR() {
    if (!this.signalR) throw 'SignalR not initialised'

    try {
      await this.signalR.start();
      console.log("SignalR Connected.")
      this.data.srConnected = true

      useSupportTicketsStore().updateTicketRoomSubscriptions()

    } catch (err) {
      console.log(err);
      setTimeout(this.startSignalR, 5000)
    }
  }

  async getJwt(): Promise<string> {
    const token = authState.value.user?.id_token
    if (token) return token

    const manager = authState.value.userManager
    if (!manager) throw new Error('No user manager')

    const user = await manager.getUser()

    return user?.id_token || ''
  }

  async logout() {
    signoutRedirect()
  }

  async loadUserData(user: User) {
    if (!user) throw new Error('Invalid user given')

    Sentry.setUser({
      email: user.profile.email,
      id: user.profile.sub
    })

    this.data.type = 'zitadel'
    this.data.isAuthenticated = true
    if (user.profile.email) {
      this.data.profileImageUrl = 'https://www.gravatar.com/avatar/' + md5(user.profile.email.toLowerCase())
      this.data.email = user.profile.email
    }

    const userDetails = (await ApiService.get('users/me')).data
    if (!userDetails || !userDetails.id || userDetails.isCustomer === undefined) throw new Error('Unable to fetch user data')

    this.data.id = userDetails.id
    this.data.isCustomer = userDetails.isCustomer
    this.data.followingSupportTickets = userDetails.followingSupportTickets
    this.data.roles = userDetails.roles || []
    this.data.organisationId = userDetails.organisationId

    if (this.data.roles?.includes('account-admin')) {
      this.data.roles.push(...[
        'view:webshop',
        'view:reports',
        'view:invoices',
        'view:support-tickets',
        'view:configurations',
      ])
    }

    if (this.data.roles?.some(role => ['view:reports', 'view:invoices'].includes(role)))
      this.data.roles.push('view:documents')
    
    if (user?.profile.name) this.data.name = user.profile.name

    const sessionContext: Record<string, any> | undefined = await this.cpApi?.generalPurposes.getApiGeneralPurposes({ 
      name: 'session-context-test',
      params: { orderBy: 'UserID ASC' },
    })

    this.startSignalR()
  }

  async authGuard(to: RouteLocation): Promise<string | boolean> {
    if (this.data.isAuthenticated) return true

    const manager = authState.value.userManager
    if (!manager) throw new Error('No UserManager')

    let user: User | null | undefined

    if (to.path == '/oidc-callback-silent') {
      await manager.signinSilentCallback()
      return false
    }
    
    if (to.path == '/oidc-callback-popup') {
      await manager.signinPopupCallback()
      return false
    }

    if (to.path == '/oidc-callback') {
      user = await manager.signinRedirectCallback()
    }

    if (!user) { 
      // User has not been set with the callback, check session
      user = await manager.getUser()
    }

    if (!user) { 
      // Get user from session failed, try silent
      try {
        user = await manager.signinSilent()
      } catch (error: any | ErrorResponse) {
        if (error.error == 'interaction_required') {
          console.log('Interaction required')
        } else {
          throw error
        }
      }
    }

    if (!user) { 
      // Silent login failed, try popup or redirect
      if (window.top && window.top === window.self) {
        // Not in iframe, redirect
        await manager.signinRedirect()
        return false
      }

      // If inside iframe, use popup
      user = await manager.signinPopup()
    }

    await manager.storeUser(user)
    await this.loadUserData(user)

    return true
  }
}
