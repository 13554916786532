import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import { createRouter } from "./router"
import VueFeather from 'vue-feather'
import { MenuPlugin } from './plugins/menu'
import { UserPlugin } from './plugins/user'
import * as Sentry from '@sentry/vue'
import i18nInstance from '@/utils/i18n'

import { createVuetify } from 'vuetify'
//import * as components from 'vuetify/components'
import * as vuetifyDirectives from 'vuetify/directives'

import Toast from "vue-toastification"

import { VueQueryPlugin } from '@tanstack/vue-query'

import "vue-toastification/dist/index.css"
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'vuetify/styles'

// Init auth
import './auth/oidc'

// Show version / deploy datetime
console.log('Build: ' + import.meta.env.MODE + ' at ' + import.meta.env.VUE_APP_DEPLOY_DATETIME)

// Create app
const app = createApp(App)
const router = createRouter(app)

// Sentry
if (import.meta.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    environment: import.meta.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
    //new Sentry.Replay(),
    ],

    tracesSampleRate: 1.0,
    tracePropagationTargets: [location.origin],
  });
}

// Notifications
app.use(Toast, {});

// Internationalisation
app.use(i18nInstance)

// Vuetify
const vuetify = createVuetify({
//  components: [components.VAlert],
  directives: vuetifyDirectives,
})
app.use(vuetify)

app.use(VueQueryPlugin)
app.use(createPinia())
app.use(new UserPlugin())
app.use(new MenuPlugin())
app.use(router)

app.component(VueFeather.name, VueFeather)

app.mount('#app')
