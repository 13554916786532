/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountUpdateDto } from '../models/AccountUpdateDto';
import type { AccountUserCreateDto } from '../models/AccountUserCreateDto';
import type { AccountUserDetailsDto } from '../models/AccountUserDetailsDto';
import type { AccountUserDtoIPageModel } from '../models/AccountUserDtoIPageModel';
import type { IActionResult } from '../models/IActionResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AccountUsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns AccountUserDtoIPageModel Success
     * @throws ApiError
     */
    public getApiUsersAccounts({
        id,
        pageNo,
        pageSize,
        searchParameter,
        orderBy,
        filters,
        lessThan,
        greaterThan,
    }: {
        id: number,
        pageNo?: number,
        pageSize?: number,
        searchParameter?: string,
        orderBy?: Record<string, string>,
        filters?: Record<string, Array<string>>,
        lessThan?: Record<string, Array<string>>,
        greaterThan?: Record<string, Array<string>>,
    }): CancelablePromise<AccountUserDtoIPageModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{id}/accounts',
            path: {
                'id': id,
            },
            query: {
                'PageNo': pageNo,
                'PageSize': pageSize,
                'SearchParameter': searchParameter,
                'OrderBy': orderBy,
                'Filters': filters,
                'LessThan': lessThan,
                'GreaterThan': greaterThan,
            },
        });
    }
    /**
     * @returns IActionResult Success
     * @throws ApiError
     */
    public postApiUsersAccounts({
        id,
        requestBody,
    }: {
        id: number,
        requestBody?: AccountUserCreateDto,
    }): CancelablePromise<IActionResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users/{id}/accounts',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns AccountUserDetailsDto Success
     * @throws ApiError
     */
    public getApiUsersAccounts1({
        id,
        accountId,
    }: {
        id: number,
        accountId: string,
    }): CancelablePromise<AccountUserDetailsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{id}/accounts/{accountId}',
            path: {
                'id': id,
                'accountId': accountId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns IActionResult Success
     * @throws ApiError
     */
    public putApiUsersAccounts({
        id,
        accountId,
        requestBody,
    }: {
        id: number,
        accountId: string,
        requestBody?: AccountUpdateDto,
    }): CancelablePromise<IActionResult> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/users/{id}/accounts/{accountId}',
            path: {
                'id': id,
                'accountId': accountId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns IActionResult Success
     * @throws ApiError
     */
    public deleteApiUsersAccounts({
        accountId,
        id,
    }: {
        accountId: string,
        id: string,
    }): CancelablePromise<IActionResult> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/users/{id}/accounts/{accountId}',
            path: {
                'accountId': accountId,
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
