/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentDto } from '../models/AttachmentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AttachmentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns string Success
     * @throws ApiError
     */
    public getApiAttachmentsSas({
        id,
        addContentDisposition,
    }: {
        id: number,
        addContentDisposition?: boolean,
    }): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/attachments/{id}/sas',
            path: {
                'id': id,
            },
            query: {
                'addContentDisposition': addContentDisposition,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns AttachmentDto Success
     * @throws ApiError
     */
    public postApiAttachments({
        formData,
    }: {
        formData?: {
            file?: Blob;
            name?: string;
            description?: string;
        },
    }): CancelablePromise<AttachmentDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/attachments',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
}
