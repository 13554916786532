import type { App } from 'vue'

const PLUGIN_TOKEN = '$menu'

interface MenuProvider {
  test: string
}

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    [PLUGIN_TOKEN]: MenuProvider
  }
}

export class MenuPlugin implements MenuProvider {

  public test = ''
  
  install(app: App): void {
    
    app.config.globalProperties[PLUGIN_TOKEN] = this
  }
}

