import { createI18n } from 'vue-i18n'

const instance = createI18n(
  {
    legacy: false,
    missingWarn: false,
    locale: 'nl',
    messages: {
      'nl': {
        prio_1: 'Urgent',
        prio_2: 'Hoog',
        prio_3: 'Normaal',
        prio_4: 'Laag',
        prio_5: 'Lange termijn',
      } 
    },
    datetimeFormats: {
      'nl': {
        short: {
          day: 'numeric', month: 'short', year: 'numeric'
        },
        long: {
          year: 'numeric', month: 'numeric', day: 'numeric',
          hour: 'numeric', minute: 'numeric'
        }
      },
    }
  }
)

export default instance

export const t = (key: string | number) => instance.global.t(key)