/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttachmentDto } from '../models/AttachmentDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MessagesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any Success
     * @throws ApiError
     */
    public postApiMessagesRead({
        id,
    }: {
        id: number,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/messages/{id}/read',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns boolean Success
     * @throws ApiError
     */
    public postApiMessagesSupportTicketsReadAll({
        ticketId,
    }: {
        ticketId: number,
    }): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/messages/support-tickets/{ticketId}/read-all',
            path: {
                'ticketId': ticketId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }
    /**
     * @returns AttachmentDto Success
     * @throws ApiError
     */
    public getApiMessagesAttachments({
        id,
    }: {
        id: number,
    }): CancelablePromise<Array<AttachmentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/messages/{id}/attachments',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns AttachmentDto Success
     * @throws ApiError
     */
    public getApiMessagesAttachments1({
        id,
        attachmentId,
    }: {
        id: number,
        attachmentId: number,
    }): CancelablePromise<AttachmentDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/messages/{id}/attachments/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns AttachmentDto Success
     * @throws ApiError
     */
    public putApiMessagesAttachments({
        id,
        attachmentId,
        name,
        description,
        formData,
    }: {
        id: number,
        attachmentId: number,
        name: string,
        description: string,
        formData?: {
            file?: Blob;
        },
    }): CancelablePromise<AttachmentDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/messages/{id}/attachments/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
                'name': name,
                'description': description,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns AttachmentDto Success
     * @throws ApiError
     */
    public deleteApiMessagesAttachments({
        id,
        attachmentId,
    }: {
        id: number,
        attachmentId: number,
    }): CancelablePromise<AttachmentDto> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/messages/{id}/attachments/{attachmentId}',
            path: {
                'id': id,
                'attachmentId': attachmentId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
