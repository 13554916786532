/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentDetailsDto } from '../models/DocumentDetailsDto';
import type { DocumentDtoIPageModel } from '../models/DocumentDtoIPageModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DocumentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns DocumentDtoIPageModel Success
     * @throws ApiError
     */
    public getApiDocuments({
        pageNo,
        pageSize,
        searchParameter,
        orderBy,
        filters,
        lessThan,
        greaterThan,
    }: {
        pageNo?: number,
        pageSize?: number,
        searchParameter?: string,
        orderBy?: Record<string, string>,
        filters?: Record<string, Array<string>>,
        lessThan?: Record<string, Array<string>>,
        greaterThan?: Record<string, Array<string>>,
    }): CancelablePromise<DocumentDtoIPageModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/documents',
            query: {
                'PageNo': pageNo,
                'PageSize': pageSize,
                'SearchParameter': searchParameter,
                'OrderBy': orderBy,
                'Filters': filters,
                'LessThan': lessThan,
                'GreaterThan': greaterThan,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns DocumentDetailsDto Success
     * @throws ApiError
     */
    public getApiDocuments1({
        id,
    }: {
        id: string,
    }): CancelablePromise<DocumentDetailsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/documents/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns binary Success
     * @throws ApiError
     */
    public getApiDocumentsDownload({
        id,
    }: {
        id: string,
    }): CancelablePromise<Blob> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/documents/{id}/download',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
