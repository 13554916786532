import { unref } from "vue"
import i18n from '@/utils/i18n'
import { format as timeFormat, formatRelative, parseISO, subDays, isToday, differenceInCalendarDays } from 'date-fns'
import { nl } from 'date-fns/locale'

export function shortDateTime(datetime?: Date | string): string {
  if (!datetime) return ''

  if (typeof datetime === 'string') {
    datetime = parseISO(datetime)
  }
  
  // get locale: console.log(unref(i18n.global.locale))

  let out = timeFormat(datetime, 'p', { locale: nl })
  if (!isToday(datetime)) {
    out = timeFormat(datetime, 'PP', { locale: nl }) + ', ' + out
  }

  return out
}

export function relativeTime(datetime?: Date | string): string {
  if (!datetime) return ''

  if (typeof datetime === 'string') {
    datetime = parseISO(datetime)
  }
  const now = new Date()
  const diff = Math.abs(differenceInCalendarDays(datetime, now))
  if (diff > 1) {
    return timeFormat(datetime, 'Pp', { locale: nl })
  } else {
    return formatRelative(datetime, now, { locale: nl })
  }
}


export function ticketIdFormatter(ticketId?: number | null) {
  ticketId = unref(ticketId)
  if (!ticketId) return
  let out = String(ticketId).padStart(8, '0')
  return (
    out.substring(0, 2) + '.' +
    out.substring(2, 5) + '.' +
    out.substring(5, 8)
  )
}