
import { defineStore } from 'pinia'
import { reactive, ref, computed, unref, watch } from 'vue'
import type { UserPlugin } from '@/plugins/user'
import type { AttachmentDto, SupportTicketMessageDto, PortalUserDto, SupportTicketDto } from '@/api'
import { useToast } from 'vue-toastification'
import { ticketIdFormatter } from '@/helpers/formatters'
import { t } from '@/utils/i18n'

export interface ChatMessageAttachment {
  id: number | null,
  file: File,
  status: string,
  progress?: number,
  rate?: number,
  estimated?: number,
  loaded?: number,
}

export const useSupportTicketsStore = defineStore('support-tickets', () => {
  const tickets: Map<number, SupportTicketDto | undefined> = reactive(new Map())
  const joinedRooms: Array<number> = []
  const attachmentUrls: Map<string, { url: string, expiresAt: number }> = reactive(new Map())
  const configurations: Record<string, any> = reactive({})

  let user: UserPlugin | null = null

  const toast = useToast()

  function setUser(target: UserPlugin) {
    user = target
  }

  function getTicket(ticketId: number | string, exact = false) {
    let ticket: (SupportTicketDto | undefined)

    const handleTicketResponse = async (responseTicket: SupportTicketDto) => {
      if (!responseTicket.id) return
      Object.assign(ticket as SupportTicketDto, responseTicket)
      if (!ticket?.id) throw 'Ticket id missing'

      tickets.set(ticket.id, ticket)

      if (ticket.configurationId && !configurations[ticket.configurationId]) {
        user?.cpApi?.generalPurposes.getApiGeneralPurposes({
          name: 'configuration-by-id',
          params: {
            code: ticket.configurationId,
            orderBy: 'PostCode ASC'
          }
        }).then((response => {
          if (response) {
            for (const item of response.Data) {
              configurations[item.configuration_code] = item
            }
          }
        }))
      }
      
      updateTicketRoomSubscriptions()
    }

    if (exact === false) {
      if (typeof ticketId !== 'number') throw 'TicketID should be a number'

      ticket = tickets.get(ticketId)
      if (!ticket) {
        ticket = reactive({})
      }
      user?.cpApi?.supportTickets.getApiSupportTickets1({ id: Number(ticketId) }).then(handleTicketResponse)
      return ticket

    } else { // exact == true
      if (typeof ticketId !== 'string') throw 'TicketID should be a string'
      ticket = reactive({})

      user?.cpApi?.supportTickets.getApiSupportTicketsExact({ exactId: ticketId }).then(handleTicketResponse)
    }

    return ticket
  }

  async function setMessageSeen(id: number, messageId: number) {
    await user?.signalR?.invoke("ReadMessage", id, messageId ) 
  }

  async function updateTicketRoomSubscriptions (force = false) {
    if (user?.signalR?.state != 'Connected') return

    for (const [ticketId, ticket] of tickets) {
      if (!ticket || !ticket.id) continue
      if (!force && joinedRooms.includes(ticket.id)) continue

      try {
        const response = await user.signalR?.invoke(
          "JoinRoom", 
          ticket.id, 
          ticket.messages?.slice(-1)[0]?.createdOn || 
            ticket.createdOn
        )

        joinedRooms.push(ticket.id)

      } catch (error) {
        return console.error(error);
      }
    }
  }

  async function sendMessage(ticketId: number, message: {}, attachments: Array<number>) {
    const messageString = JSON.stringify(message)

    try {
      const response = await user?.signalR?.invoke("NewMessage", ticketId, messageString, attachments)
    } catch (err) {
        return console.error(err)
    }
  }

  function onMessageReceived(ticketId: number, incomingMessage: SupportTicketMessageDto) {
    const ticket = tickets.get(ticketId)
    if (!ticket || !ticket.id) throw 'Ticket not found'
    if (incomingMessage === undefined) throw 'Incoming message missing ID'
    if (!ticket.messages) ticket.messages = []

    const existingIndex = ticket.messages.findIndex((existingMessage: SupportTicketMessageDto) => existingMessage.id == incomingMessage.id)
    if (existingIndex > -1) {
      console.log('TODO: Check if incoming message actually has differences')
      ticket.messages[existingIndex] = incomingMessage
      return
    }

    ticket.messages.push(incomingMessage)

    if (incomingMessage.sendBy?.id != user?.data.id) {
      let exactId = ticket.exactId ? ticketIdFormatter(ticket.exactId) : t('Wordt toegewezen')
      toast.info(
          'Nieuw bericht van ' + 
          incomingMessage.sendBy?.fullName +
          ' (' + exactId + ')'
      )
    }
  }

  function onMessageRead(ticketId: number, messageUser: PortalUserDto, messageId: number) {
    const ticket = tickets.get(ticketId)
    if (!ticket || !ticket.id) throw new Error('Ticket is not loaded yet: '+ ticketId)
    if (isNaN(messageId)) throw new Error('Incoming message missing ID')
    
    const message = ticket.messages?.find(msg => msg.id == messageId)
    if (!message) throw new Error('Message not found')
    
    if (message.readBy == undefined) {
      message.readBy = []
    } 
    
    message.readBy.push({
      user: messageUser
    })
  }

  async function getAttachmentUrl(id: number, addContentDisposition = false) {
    const cacheKey = JSON.stringify({id, addContentDisposition})
    let cached = attachmentUrls.get(cacheKey)
    if (
      !cached || 
      (cached.expiresAt - (new Date()).getTime()) < 60000 // Expires in less than 60 seconds
    ) {
      const data = await user?.cpApi?.attachments.getApiAttachmentsSas({
        id: id,
        addContentDisposition
      })

      if (!data) throw new Error('Error fetching attachment URL')

      const expireString = new URLSearchParams(new URL(data).search).get('se')
      if (!expireString) throw new Error('Invalid expire date')
      const expiresAt = new Date(expireString)

      cached = { 
        url: data,
        expiresAt: expiresAt.getTime()
      }
      attachmentUrls.set(cacheKey, cached)
    } 
    return cached.url
  }

  const attachmentLoading = new Set()
  async function loadMessageImages(ticketId: number, messageId: number) {
    const key = ticketId + '-' + messageId

    if (attachmentLoading.has(key)) return

    attachmentLoading.add(key)

    const ticket = tickets.get(ticketId)
    if (!ticket || !ticket.id) throw new Error('Ticket is not loaded yet: '+ ticketId)
    if (isNaN(messageId)) throw new Error('Incoming message missing ID')
    
    const message = ticket.messages?.find(msg => msg.id == messageId)
    if (!message) throw new Error('Message not found')

    if (!message.attachments) return

    const promises = message.attachments.filter(
      file => file.id && file.contentType?.startsWith('image/')
    ).map(file => {
      if (!file.id) return
      
      getAttachmentUrl(file.id)
    })

    await Promise.all(promises)

    attachmentLoading.delete(key)
  }

  return { 
    setUser,
    getTicket,
    setMessageSeen,
    sendMessage,
    onMessageReceived,
    onMessageRead,
    updateTicketRoomSubscriptions,
    getAttachmentUrl,
    loadMessageImages,
    attachmentUrls,
    configurations,
  }
})