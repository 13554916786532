/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class GeneralPurposesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns any Success
     * @throws ApiError
     */
    public getApiGeneralPurposes({
        name,
        page,
        perPage,
        params
    }: {
        name: string,
        page?: number,
        perPage?: number,
        params?: any,
    }): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/general-purposes/{name}',
            path: {
                'name': name,
            },
            query: {
                'page': page,
                'perPage': perPage,
                ...params
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
}
