/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JsonNode } from '../models/JsonNode';
import type { SynergyMockCreateDto } from '../models/SynergyMockCreateDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SynergyMockService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns JsonNode Success
     * @throws ApiError
     */
    public postApiSynergyMockContactPerson({
        requestBody,
    }: {
        requestBody?: SynergyMockCreateDto,
    }): CancelablePromise<Record<string, JsonNode>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/synergy-mock/ContactPerson',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
            },
        });
    }
}
