/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatablePortalUserDto } from '../models/CreatablePortalUserDto';
import type { CurrentUserDetailsDto } from '../models/CurrentUserDetailsDto';
import type { PortalUserDetailsDto } from '../models/PortalUserDetailsDto';
import type { PortalUserListItemDtoIPageModel } from '../models/PortalUserListItemDtoIPageModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @returns PortalUserListItemDtoIPageModel Success
     * @throws ApiError
     */
    public getApiUsers({
        pageNo,
        pageSize,
        searchParameter,
        orderBy,
        filters,
        lessThan,
        greaterThan,
    }: {
        pageNo?: number,
        pageSize?: number,
        searchParameter?: string,
        orderBy?: Record<string, string>,
        filters?: Record<string, Array<string>>,
        lessThan?: Record<string, Array<string>>,
        greaterThan?: Record<string, Array<string>>,
    }): CancelablePromise<PortalUserListItemDtoIPageModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users',
            query: {
                'PageNo': pageNo,
                'PageSize': pageSize,
                'SearchParameter': searchParameter,
                'OrderBy': orderBy,
                'Filters': filters,
                'LessThan': lessThan,
                'GreaterThan': greaterThan,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns PortalUserDetailsDto Success
     * @throws ApiError
     */
    public postApiUsers({
        requestBody,
    }: {
        requestBody?: CreatablePortalUserDto,
    }): CancelablePromise<PortalUserDetailsDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns CurrentUserDetailsDto Success
     * @throws ApiError
     */
    public getApiUsersMe(): CancelablePromise<CurrentUserDetailsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me',
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * @returns PortalUserDetailsDto Success
     * @throws ApiError
     */
    public getApiUsers1({
        id,
    }: {
        id: number,
    }): CancelablePromise<PortalUserDetailsDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
            },
        });
    }
}
